<template>
  <Layout>
    <div class="base-width">
      <div class="main-pay text-center s16 c-card">
        <!-- 返回 -->
        <div class="flex-left">
          <BaseButton style-type='light' class="btn-back" :hover='false' @click="$router.back()">
            <span>返回</span>
            <img src="@/assets/images/icon-back-2.png">
          </BaseButton>
        </div>
        <!-- <div class="color-4 t1 lh-30">
          账期采购支付剩余时间：10时45分18 秒
        </div> -->
        <div class="font-medium t2 price flex-center">
          <span class="s32 color-2">¥{{ baseObj.order_amount }}</span>
          <span class="s26 color-red" v-if="baseObj.pay_type == 2">（预付款）</span>
        </div>
        <div class="color-red t3 lh-30" v-if="baseObj.pay_type == 2">
          为保证三流合一，建议选择“线下支付”采用企业对公支付
        </div>
        <div class="line"></div>
        <!-- 二维码 -->
        <div class="qrcode">
          <!-- <img src="@/assets/images/icon-pay-code.png" class="code"> -->
          <img :src="qrcode_url" class="code">
          <div class="qrcode-bd flex-center">
            <img src="@/assets/images/icon-scan.png">
            <div class="text s18 color-2 text-left">
              打开手机微信<br>
              扫一扫继续付款
            </div>
          </div>
        </div>
      </div>
    </div>
    <BaseDialog :visible.sync='visible' title="信息提示" :cancel='false'>
      <div class="s26 color-2 font-medium text-center dialog-text">
        请尽快进行完成支付<br>
        该订单将保留30分钟
      </div>
    </BaseDialog>
  </Layout>
</template>

<script>
import { OrderAmountApi, OrderPayCheckApi } from "@/request/api/order";
export default {
  created() {
    this.order_id = this.$route.query.order_id;
    this.merge_order_sn = this.$route.query.merge_order_sn;
    this.qrcode_url = this.$route.query.qrcode_url;
    this.order_sn = this.$route.query.order_sn;
    OrderAmountApi({ order_id: this.order_id, merge_order_sn: this.merge_order_sn }).then(({ status, data }) => {
      if (status == 200) {
        this.baseObj = data;
      }
    })
    this.timer = setInterval(() => {
      this.checkFn();
    }, 2000)
  },
  data() {
    return {
      order_id: "",
      merge_order_sn: "",
      qrcode_url: "",
      order_sn: "",
      baseObj: "",
      payWay: 1,
      visible: false,
      timer: null,
    };
  },
  methods: {
    checkFn() {
      OrderPayCheckApi({ order_sn: this.order_sn }).then(({ status, data }) => {
        if (status == 200) {
          if (data.pay_status == 1) {
            this.$router.push(`/pay-success?order_sn=${this.order_sn}&order_id=${this.order_id}`);
            clearInterval(this.timer);
          }

        } else {
          clearInterval(this.timer);
        }
      })
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
};
</script>

<style lang="scss" scoped>
.main-pay {
  padding: .3rem;
  padding-bottom: .8rem;
  margin-top: .37rem;
  margin-bottom: .6rem;

  .btn-back {
    width: 1rem;

    img {
      width: .13rem;
      margin-left: .04rem;
    }
  }

  .t1 {
    margin-top: .02rem;
  }

  .price {
    margin-top: .12rem;
  }

  .t3 {
    margin-top: .14rem;
  }

  .lh-30 {
    line-height: calc(30 / 16 * 1em);
  }

  .line {
    height: 1px;
    background-color: #e6f2f8;
    margin: .23rem 0 .2rem;
  }

  .btns {
    margin-top: .36rem;

    .btn {
      width: 1.3rem;
      line-height: 0.5rem;
      border-radius: 0.04rem;
      margin: 0 .1rem;
      text-align: center;
      cursor: pointer;
    }

    .btn-1 {
      background: #e01624;
    }
  }
}

.dialog-text {
  margin: 0.38rem 0 .23rem;
  line-height: calc(42 / 26 * 1em);
}

.qrcode {
  width: 3rem;
  border-radius: 0.04rem;
  border: solid 0.01rem #e6f2f8;
  padding: .17rem .15rem .21rem;
  margin: .3rem auto 0;

  .code {
    display: block;
    width: 100%;
  }

  &-bd {
    margin-top: .06rem;

    img {
      width: .43rem;
      margin-right: .12rem;
    }

    .text {
      line-height: calc(26 / 18 * 1em);
    }
  }
}
</style>